import Demo from "./Demo"
import Intensive from "./Intensive"
import Regular from "./Regular"
import Wedding from "./Wedding"
import React from 'react';

import { ReactComponent as DemoIcon } from '../../_bin/svg/courses_menu/lekcja_probna.svg';
import { ReactComponent as RegularIcon } from '../../_bin/svg/courses_menu/kurs_regularny.svg';
import { ReactComponent as IntensiveIcon } from '../../_bin/svg/courses_menu/kurs_intensywny.svg';
import { ReactComponent as WeddingIcon } from '../../_bin/svg/courses_menu/kurs_obraczki.svg';

import { ReactComponent as DemoImg } from '../../_bin/svg/courses/lekcja_probna.svg';
import { ReactComponent as RegularImg } from '../../_bin/svg/courses/kurs_regularny.svg';
import { ReactComponent as IntensiveImg } from '../../_bin/svg/courses/kurs_intensywny.svg';
import { ReactComponent as WeddingImg } from '../../_bin/svg/courses/kurs_obraczki.svg';

const content = {
    path: "kursy",
    coursesList: [
        {
            name: "lekcja próbna",
            id: "lekcja_probna",
            component: Demo,
            icon: <DemoIcon/>,
            img: <DemoImg/>,
        }, {
            name: "kurs regularny",
            id: "kurs_regularny",
            component: Regular,
            icon: <RegularIcon/>,
            img: <RegularImg/>,
        }, {
            name: "kurs intensywny",
            id: "kurs_intensywny",
            component: Intensive,
            icon: <IntensiveIcon/>,
            img: <IntensiveImg/>,
        }, {
            name: "warsztaty obrączkowe",
            id: "kurs_obraczki",
            component: Wedding,
            icon: <WeddingIcon/>,
            img: <WeddingImg/>,
        }
    ]
}

export default content
