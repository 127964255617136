const content = {
    email: {
        label: 'e-mail',
        value: 'szkolazlotnictwa@gmail.com'
    },
    phone: {
        label: 'phone',
        value: '733 206 966'
    },
    address: {
        label: 'address',
        value: [
                "5 Józefitów str",
                "level -1",
                "30-039 Cracow",
        ]
    },
    nip: {
        label: 'nip',
        value: '678 299 53 29'
    },
    bankAccount: {
        label: 'bank account',
        value: '44 1050 1445 1000 0097 2077 8399'
    }
}

export default content
