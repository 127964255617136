import React from 'react';

const content = {
    title: "PLIKI COOKIES",
    text: <>
        <p>Strona szkolazlotnictwa.pl wykorzystuje pliki Cookies i podobnych technologii m.in. w celach statystycznych. Cookies to niewielkie pliki tekstowe wysyłane przez stronę internetową do urządzenia użytkownika.</p>
        <br/>
        <p>Strona korzysta z plików cookies pozwalających na zapamiętanie ustawień użytkownika (np. język) oraz wydajnościowych, służących do zbierania anonimowych informacji o sposobie korzystania ze strony.</p>
        <br/>
        <p>W przeglądarce internetowej można zmienić ustawienia dotyczące Cookies. Brak zmiany tych ustawień oznacza akceptację dla stosowanych tu Cookies.</p>
        </>};

export default content;