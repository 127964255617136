const data = {
  contactInfo: {
    email: 'szkolazlotnictwa@gmail.com',
    address: `Józefitów 5, Kraków`,
    phone: '733 206 966',
    nip: '678 299 53 29',
    bankAccount: '44 1050 1445 1000 0097 2077 8399',
    facebook: 'https://www.facebook.com/szkolazlotnictwa/',
    instagram: 'https://www.instagram.com/szkolazlotnictwa/',
  },
  cright: {
    cright: 'Copyright © 2022-2024',
    createdby: 'created by Dominika Hapka, Krzysztof Kokosiński'
  },
  prices: {
    semester: 5200,
    weddingBands: 1200,
    oneHour: 160,
    extraCourseHour: 160,
    demo: 800,
  },
  hours: {
    weddingBandsCourseDuration: 7,
    regularCourseTotalDuration: 70,
    regularCourseMeeingDuration: 5,
    weekdays:
      {
        morning: "9:00 - 14:00",
        evening: "15:00 - 20:00"
      },
      weekend:
      {
        morning: "9:00 - 14:00",
        // evening: "14:00 - 19:00"        
      }
  },
  docs: {
    regulations: 'https://docs.google.com/document/d/1z4QJnMkY9cCOsTdHVF5h5ZwkdszMqyQ-H0RDv1Leu-k/export?format=pdf',
  },
};

export default data;
