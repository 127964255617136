import magdalena from '../../_bin/img/magdalena.jpg'
import agnieszka from '../../_bin/img/agnieszka.jpg'
import wiktor from '../../_bin/img/wiktor.jpg'
import aleksandra from '../../_bin/img/aleksandra.jpg'

const oMagdzie = [
  `Pomysłodawczyni i założycielka szkoły. Mistrzyni rzemiosła w zawodzie złotnik–jubiler. Absolwentka edukacji artystycznej Uniwersytetu Pedagogicznego w Krakowie. Doświadczenie w zawodzie złotnika zdobywała pracując w cenionej krakowskiej pracowni złotniczej mistrza Tadeusza Henzla. Jej ulubionym medium w pracy artystycznej są kości zwierzęce. Biżuteria powinna być funkcjonalna, wygodna i oczywiście dobrej jakości. Ma na swoim koncie liczne wystawy zagraniczne. Więcej informacji o Magdzie i jej twórczości znajdziecie na stronie:`,
];

const oAdze = [`Z wykształcenia prawnik. Ukończyła studia podyplomowe Projektowania Biżuterii na ASP im. Władysława Strzemińskiego w Łodzi. Jubilerstwo stało się jej życiową pasją. Nieustannie zgłębia nowe techniki, tj. haft sutaszowy, wirewrapping czy Art Clay. Projektuje i wykonuje autorską biżuterię pod marką Jachyra Jewellery. `,
  `Kocha koty, góry i pieczenie ciastek. Te ostatnie często goszczą w naszej szkole.`,
];

const oOli = [
  `Absolwentka historii sztuki Uniwersytetu Jagiellońskiego. Zaraz po studiach postanowiła wcielić w życie swoje odwieczne marzenie o byciu złotnikiem. Ukończyła naszą szkołę. Zachwyca ją pomysłowość dawnych mistrzów złotnictwa i pełna uroku symbolika dawnych przedmiotów. W swoich pracach łączy inspiracje zaczerpnięte ze sztuki nowożytnej z własnymi pomysłami, a szlachetne metale z masą polimerową. Pracuje też jako instruktor w jednym z krakowskich domów kultury. Opiłki wolnego czasu spędza śpiewając w chórze i spacerując w towarzystwie psa Szafira.`
];

const oWiktorze = ['Od czasów licealnych każdą wolną chwilę spędzał w sklepie jubilerskim swojego taty. Ze Szkołą Złotnictwa związał się w 2022 roku - najpierw jako kursant, obecnie jako instruktor. Oprócz zajęć dla grup początkujących w szkole, projektuje i wykonuje grillzy z metali szlachetnych. W swojej złotniczej praktyce sporo eksperymentuje i konsekwentnie poszerza swój warsztat.']

const content = [
  {
    name: "Magdalena Maślerz",
    about: oMagdzie,
    foto: magdalena,
    web: "magdalenamaslerz.pl"
  },
  {
    name: "Agnieszka Żołneczko",
    about: oAdze,
    foto: agnieszka,
    web: "agnieszkajachyra.com.pl"
  },
  {
    name: "Aleksandra Poźniak",
    about: oOli,
    foto: aleksandra,
    // web: "agnieszkajachyra.com.pl"
  },
  {
    name: "Wiktor Partyka",
    about: oWiktorze,
    foto: wiktor,
    // web: "agnieszkajachyra.com.pl"
  }
];

export default content;