import React from 'react';
import config from '../../../_config/config'

const content = {
  name: "lekcja próbna",
  description: <>
    Są to pojedyncze zajęcia podczas których poznasz warsztat i wykonasz wcześniej skonsultowany projekt. <br /><br />
    Podczas tej jednodniowej przygody przekonasz się czym jest złotnicze rzemiosło i stwierdzisz, czy chcesz zostać u nas na dłużej.
    <br /><br />
    Oferujemy również voucher prezentowy na lekcję próbną.
    </>,
  priceInfo: <>
    czas: 5h (termin dostosowany indywidualnie)<br />
    cena: {config.prices.demo} zł
  </>
}

export default content;