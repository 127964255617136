import React from 'react';
import config from '../../../_config/config'

const content = {
  name: "kurs intensywny",
  description: <>
    Przyśpieszone szkolenia, w czasie którego uczestnicy realizują materiał z całego semestru regularnego w ciągu jednego miesiąca odbywają się kilka razy w roku. Takie semestry intensywne organizujemy w sezonie zimowym (luty) oraz letnim (lipiec, sierpień, wrzesień). Zajęcia odbywają się codziennie w kameralnych grupach.<br /><br />
    termin: luty / lipiec / sierpień / wrzesień (miesiąc do wyboru)<br />
    pon-pt: grupa poranna ({config.hours.weekdays.morning})<br />

  </>,
  priceInfo: <>
    spotkania: 5x w tygodniu <br />
    czas trwania: {config.hours.regularCourseTotalDuration}h ({config.hours.regularCourseTotalDuration/config.hours.regularCourseMeeingDuration} spotkań w ciągu jednego miesiąca)<br />
    cena: {config.prices.semester} zł
  </>
}

export default content;