import React from 'react'
import content from './content'
import { Typography } from '@material-ui/core'

const  CookiesDetails = () => {
    return <div className="maxwidth">
        <Typography variant="h6">{content.title}</Typography>
        <br/>
        <Typography variant="body2">{content.text}</Typography>
    </div>
}

export default CookiesDetails;