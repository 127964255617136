import React from 'react';
import CustomLink from '../CustomLink'
import { Grid, Typography } from '@material-ui/core';
import content from "./content"

const nameMargin = 0; //moim zdaniem 10 ;P
const nameHeight = 70;

function About(props) {

  return (
    <>
      {content.map(item =>
        <Grid container style={{ marginBottom: 150 }} key={item.name}>
          <Grid item className="leftColumn" xs={12} sm={5}>
            <Grid item xs={12} sm={9}
              className="onePixBorder"
              style={{ padding: "20px", textAlign: "center", margin: nameMargin + "px 0px", minHeight: nameHeight }}
            >
              <Typography variant='h6' style={{ verticalAlign: "middle", display: "inline" }}>{item.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <img src={item.foto} alt={item.name} width="100%" />
            </Grid>
          </Grid>
          <Grid item className="rightColumn" style={{ marginTop: 2 * nameMargin + nameHeight }} xs>
            <Grid item>

              {[...item.about, <CustomLink
                href={"http://" + item.web}
              >
                {item.web}
              </CustomLink>
              ].map((paragraph, index) => <Typography
                variant='body2'
                key={item.name + index}
                style={{ marginBottom: 18 }}
              >
                {paragraph}
              </Typography>
              )
              }

            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default About;
