import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    color: "inherit",
    verticalAlign: "middle",
    textAlign: "center",
    maxWidth: "250px",
    minWidth: "90px"
  },
  navButton: {
    height: "calc(30px + 1vh)",
    margin: "25px 5px",
    border: "1px solid black",
    transitionDelay: "0s",
    transitionDuration: "0.4s",
    transitionProperty: "color",
    transitionTimingFunction: "ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    }
  }
}));

function NavButton(props) {
    const classes = useStyles();
    return <Link style={props.style} href={props.href} className={classes.link} underline="none" target={props.target || null}>
          <div className={classes.navButton}>
            <Typography variant='body2' style={{ textTransform: "uppercase", fontWeight: 700, fontSize: 11 }}>{props.text}</Typography> 
          </div>
        </Link>
    
}
//style={{textTransform: uppercase}}

export default NavButton;