import React from 'react';
import { Grid, Typography } from '@material-ui/core';

function DingDong(props) {

    const alignDong = props.right
        ? "left"
        : "right"

    const title = <div style={{ display: 'inline-block', verticalAlign: 'top', margin: '0 10px 0 10px' }}>
        <Typography variant='body1'>
            {props.title}
        </Typography>
    </div>

    const dot = <div
        style={{
            height: 27,
            width: 27,
            backgroundColor: '#AC893A',
            border: 1,
            borderColor: 'black',
            borderStyle: 'solid',
            borderRadius: "50%",
            display: 'inline-block'
        }}
    />

    const line = (width) => <span
        style={{
            verticalAlign: 'super'
        }}>
        <div
            style={{
                display: 'inline-block',
                backgroundColor: null,
                borderTop: '1px solid black',
                width: width,
                paddingBottom: '8px'            
            }}
        />
    </span>

    const longDong = props.right
        ? <>{line('25%')}{dot} {title}</>
        : <>{title}{dot}{line('33%')}</>

    return (
        <Grid
            item
            xs
            style={{
                padding: "27px 0 25px 0",
                textAlign: alignDong,
                alignItems: 'center',
                verticalAlign: 'middle'
            }}>
            {longDong}
        </Grid>
    );
}

export default DingDong;
