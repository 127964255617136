import config from '../../_config/config'
import React from 'react'

const content = [
        {
            q: "Czy w szkole obowiązują ograniczenia wiekowe?",
            a: "Tak. Na kurs mogą zapisać się osoby, które ukończyły 18 lat. Nie ma górnej granicy wieku."
        },
        {
            q: "Czy zajęcia odbywają się również w weekendy?",
            a: "Przeważnie zajęcia odbywają się w tygodniu. Jeśli zbierze się odpowiednia liczba uczestników, lekcje mogą odbywać się również w soboty i niedziele."
        },
        {
            q: "Czy muszę mieć doświadczenie w branży jubilerskiej lub umiejętności artystyczne, by zapisać się na kurs złotnictwa?",
            a: "Nie. Kurs jest przeznaczony dla wszystkich, którzy interesują się złotnictwem, bez względu na to czy mają już doświadczenie, czy dopiero zaczynają."
        },
        {
            q: "Czy Szkoła Xerion należy do Rejestru Instytucji Szkoleniowych?",
            a: "Tak. Szkoła należy do Rejestru Instytucji Szkoleniowych, więc, jeżeli jesteś bezrobotny, istnieje możliwość dofinansowania Twojego kursu przez Urząd Pracy. W takiej sytuacji należy najpierw skontaktować się z Urzędem Pracy. Nasz numer w rejestrze to: 2.12 / 00237 / 2013"
        },
        {
            q: "Jakie uprawnienia posiada absolwent Szkoły?",
            a: "Po zakończeniu całego kursu i zdaniu egzaminu końcowego każdy absolwent otrzymuje świadectwo, na którym szczegółowo opisane są umiejętności teoretyczne i praktyczne, jakie zdobył w Szkole Xerion. Na świadectwie znajduje się także ocena z egzaminu końcowego i zdjęcie pracy końcowej. Istnieje możliwość wydania świadectwa również w języku angielskim. Osoba, która ukończyła nasz kurs posiada umiejętności pozwalające jej na samodzielne wykonywanie zawodu złotnika - jubilera."
        },
        {
            q: "Czym się różni zwykły kurs złotnictwa od intensywnego?",
            a: "Semestr kursu intensywnego jest przyśpieszonym semestrem kursu złotnictwa. Ilość godzin oraz cena jest taka sama w obu przypadkach. Podczas zajęć intensywnych materiał z całego semestru przerabiany jest w ciągu niecałego miesiąca, a zajęcia odbywają się codziennie od poniedziałku do piątku."
        },
        {
            q: "Mam wadę wzroku – czy mogę brać udział w kursie?",
            a: "Tak, ale konieczna jest konsultacja z okulistą oraz okulary. Na zajęciach wykonujemy precyzyjne czynności, więc należy zapewnić sobie odpowiedni komfort pracy."
        },
        {
            q: "Czy są jakieś przeciwwskazania odnośnie uczestnictwa w zajęciach?",
            a: "Tak. Jeśli Twój stan zdrowia nie pozwala Ci na wykonywanie precyzyjnych czynności lub jeżeli jesteś w ciąży, niestety, nie możesz uczestniczyć w kursie."
        },
        {
            q: "Czy zajęcia mogą być prowadzone w języku angielskim?",
            a: "Tak, po wcześniejszym uzgodnieniu."
        },
        {
            q: "Nie udało Ci się odnaleźć odpowiedzi na Twoje pytanie ? Potrzebujesz bardziej szczegółowej odpowiedzi?",
            a: <>Napisz do nas: <a href={"mailto:" + config.contactInfo.email}>{config.contactInfo.email}</a>.</>
        }
    ]

export default content;

