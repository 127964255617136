import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Typography } from '@material-ui/core';
import courses from "../content"

const useStyles = makeStyles(theme => ({
  courseCard: {
    margin: "0 auto"
  },
  courseImg: {
    // padding: "50px",
  }
}));

function Common() {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        {courses.coursesList.map((item, index) =>

          <Grid item container xs={12} sm={6} md={3} style={{ zIndex: 0 }} key={item.name}>
            <div className={classes.courseCard + " zoom"}>
              <Grid item xs style={{ padding: "0 0 50px 0", margin: "auto", width: "200%", maxWidth: "170px" }}>
                <Link href={`/kursy/${item.id}`} underline="none">
                  {item.icon}
                  <Grid item xs style={{ textAlign: 'center', padding: "25px 0 0 0" }}>
                    <Typography variant="h6" style={{ color: "black", lineHeight: "140%" }}>
                      {item.name}
                    </Typography>
                  </Grid>
                </Link>
              </Grid>
            </div>

          </Grid>

        )}

      </Grid>
    </>
  );
}
export default Common;
