import React from 'react';

const content = {
    description: <>Xerion to{'\u00A0'}pierwsza prywatna szkoła złotnictwa, założona w{'\u00A0'}2013 roku 
    w{'\u00A0'}Krakowie. W{'\u00A0'}ramach naszych kursów zdobędziesz niezbędne umiejętności 
    potrzebne do{'\u00A0'}realizacji autorskich projektów.
    Poznasz materiały, narzędzia, techniki oraz historię złotnictwa.
    <br/>
    <br/>
    </>,

    coursesLinkText: `Więcej informacji o\u00A0naszej ofercie znajdziesz w\u00A0zakładce\u00A0KURSY`,

    babble: <>
        Zajęcia pozwalają odkryć przyjemność płynącą 
        z{'\u00A0'}pracy manualnej. 
        
        Nasza pracownia to{'\u00A0'}doskonałe miejsce 
        dla kreatywnych, ceniących rzemiosło osób.<br/><br/><br/>

        Kursy odbywają się w{'\u00A0'}kompleksowo wyposażonej 
        pracowni, w{'\u00A0'}skład której wchodzi:<br/><br/>

        - duża sala<br/>
        - mała sala<br/>
        - sala obrączek<br/>
        - narzędziownia<br/>
        - miejsce do wypoczynku<br/><br/><br/>
    </>,
    
    moreBabble: <>Szkoła to miejsce pracy, spotkań artystów i{'\u00A0'}każdego, kto chce rozwijać swoje twórcze umiejętności.
    Jesteśmy otwarci zarówno dla osób początkujących, jak i{'\u00A0'}zaawansowanych.
    Chętnie dzielimy się zdobytą wiedzą prowadząc kursy jubilerskie oraz warsztaty robienia obrączek ślubnych.</>,

    smallRoom: "mała sala",
    bigRoom: "duża sala",
    kitchen: "strefa relaksu"
}

export default content
