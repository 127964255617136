const content = {
    email: {
        label: 'e-mail',
        value: 'szkolazlotnictwa@gmail.com'
    },
    phone: {
        label: 'telefon',
        value: '733 206 966'
    },
    address: {
        label: 'adres',
        value: [
                "ul. Józefitów 5",
                "poziom -1",
                "30-039 Kraków"
        ]
    },
    description: {
        label: 'Kontakt ze szkołą',
        value: 'Prosimy, aby kontaktować się z nami mailowo. Nie mamy sekretariatu ani osoby, która zajmuje się telefonem i jeśli zadzwonisz w czasie zajęć - nie będziemy mogli odebrać telefonu. Odpiszemy na każdą wiadomość.'
    },
    nip: {
        label: 'NIP',
        value: '678 299 53 29'
    },
    bankAccount: {
        label: 'nr konta',
        value: '44 1050 1445 1000 0097 2077 8399'
    }
}

export default content
