import React from 'react';
import Crotch from "./Common"
import content from "./content"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function Courses() {
  return (
    <Router>
      <Switch>
        {content.coursesList.map(item =>
          <Route path={`/${content.path}/${item.id}`} key={`route_${item.id}`}>
             <item.component img={item.img}/>
          </Route>
        )}
        <Route path="/">
          <Crotch />
        </Route>
      </Switch>
    </Router>
  );
}

export default Courses;
