const content = {
  links: [
    {
      text: "O nas",
      href: "/onas"
    },
    {
      text: "Kursy",
      href: "/kursy"
    },
    {
      text: "Kontakt",
      href: "/kontakt"
    },
    {
      text: "Blog",
      href: "http://blog.szkolazlotnictwa.pl/",
      target: "_blank"
    }
  ]
};

export default content;