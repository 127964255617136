import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, SwipeableDrawer, Hidden } from '@material-ui/core';
import NavButton from './components/NavButton';
import content from "./content"
import Footer from "../Footer"
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';
import Logo from '../../_bin/svg/logotyp.svg';

const useStyles = makeStyles(theme => ({
  navRow: {
    textAlign: "center",
    display: "flex",
    margin: "0 10px",
    top: 0,
  }
}));


const desktopMenuButtons = content.links.map((link, index) => {
  return <NavButton {...link} style={{ width: "25%" }} key={'desktop_menu_link_' + index} />
})

const mobileMenuButtons = content.links.map((link, index) => {
  return <NavButton {...link} style={{ width: "500px" }} key={'desktop_menu_link_' + index} />
})

const logoLink = <Link href="/" style={{ display:"flex", flex: "auto" }}>
  <span style={{
        width: "100%",
        minWidth: "200px",
        maxWidth: "400px"
  }}>
    <img src={Logo} alt="Szkoła Złotnictwa" />
  </span>
</Link>

function Header() {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (

    <Grid item xs={12} md={12} lg={8} xl={6} className={classes.navRow} style={{ height: "auto" }}>
      {/* mobile menu */}
      <SwipeableDrawer
        anchor="left"
        open={isMenuOpen}
        onOpen = {() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
        onClick={() => setIsMenuOpen(false)}
      >
        <div style={{ height: "100%", display: "grid", maxWidth: 300 }}>
          <div style={{ textAlign: "center", padding: 15 }}>
            <div style={{ padding: "35px 15px" }}>{logoLink}</div>
            {mobileMenuButtons}
          </div>
          <Grid item container justify='center' xs={12} style={{ alignSelf: "end", display: "flex" }}>
            <Footer />
          </Grid>
        </div>
      </SwipeableDrawer>

      {/* mobile */}
      <Hidden mdUp>
        <Grid container style={{ padding: "10px 0 10px 0", flexWrap: "nowrap" }}>
          <Grid item className='zoom' style={{ color: "#987D3F", alignContent: "center", display: "flex", paddingRight: "2vw" }}>
            <MenuIcon fontSize="large" onClick={() => setIsMenuOpen(true)} />
          </Grid>
          <Grid item container style={{ alignContent: "center", display: "flex", alignItems: "center", paddingTop: "0.5vw" }}>
            {logoLink}
          </Grid>
        </Grid>
      </Hidden>

      {/* desktop */}
      <Hidden smDown>
        <Grid item container className="leftColumn" direction="row" justify="flex-start" style={{ padding: "25px 0", alignContent: "center", display: "flex" }} xs={5}>
          <Grid item xs={9} style={{ display: "flex", alignItems: "center", margin: "10px" }}>
            {logoLink}
          </Grid>
        </Grid>
        <Grid item container style={{ margin: `0 -5px 0 -5px` }} className="rightColumn" direction="row" xs>
          {desktopMenuButtons}
        </Grid>
      </Hidden>
    </Grid>

  );
}
export default Header;