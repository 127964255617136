import React from 'react';
import { Grid, Link, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import content from "./content"
import config from '../../_config/config'

import Eng from '../../_bin/svg/footer/eng_ikona.svg';
import Pl from '../../_bin/svg/footer/pl_ikona.svg';
import Insta from '../../_bin/svg/footer/instagram_ikona.svg';
import Fb from '../../_bin/svg/footer/facebook_ikona.svg';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.secondary.light,
    right: "auto",
    color: "black",
    padding: "10px 0"
  },
  footerIcon: {
    height: 30,
    padding: 15
  },
  link: {
    color: "inherit !important",
    fontSize: "14px",
    textDecoration: "inherit !important",
    backgroundColor: "inherit !important"
  }
}));

function Footer(props) {
  const classes = useStyles();
  const lang = props.language === "english" 
    ? <img src={Pl} alt="English" className={classes.footerIcon} />
    : <img src={Eng} alt="English" className={classes.footerIcon} />
  return (
    <Grid item container justify='center' style={{ maxHeight: 230 }} className={classes.footer} >
      <Grid item container xs={10} md={10} xl={6}>
        <Grid item container className="leftColumn" direction="row" alignItems="center" xs={12} md={5}>
          <Grid item xs={12} lg={8} style={{}}>
            <Typography variant='caption'>
            <Link className={classes.link} href={"mailto:"+config.contactInfo.phone}>{config.contactInfo.email}</Link><br/>
            <Link className={classes.link} href={"tel:"+config.contactInfo.phone}>{config.contactInfo.phone} {config.contactInfo.address}</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} lg>
              <Typography variant='caption'>
                <Link className={classes.link} href={config.docs.regulations}>{content.regulations}</Link><br/>
                <Link className={classes.link} href="/FAQ">{content.FAQ}</Link>                
              </Typography>
            </Grid>
        </Grid>
        <Grid item container className="rightColumn" direction="row-reverse" alignItems="center" xs={12} md>

        <Grid item container direction="row-reverse" xs={12} md={6}>
            {lang}
            <Link href={config.contactInfo.instagram}><img src={Insta} alt="Instagram" className={classes.footerIcon} /></Link>
            <Link href={config.contactInfo.facebook}><img src={Fb} alt="Facebook" className={classes.footerIcon} /></Link>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Hidden xsDown style={{ fontSize: 14 }}>
                {config.cright.cright}<br/>
                {config.cright.createdby}
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  );
}
export default Footer;