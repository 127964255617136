import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Home from '../Home';
import About from '../About';
import Courses from '../Courses';
import Contact from '../Contact';
import Footer from '../Footer';
import Faq from '../FAQ';
import Cerberek from '../Cerberek';
import CookiesDetails from '../CookiesDetails';

const useStyles = makeStyles(theme => ({
  mainMargin: {
    [theme.breakpoints.up('sm')]: {
      margin: '96px 10px 148px 10px'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px 10px 148px 10px'
    },
  }
}
));

const Main = () => {
  const [language, setLanguage] = useState('polski');
  const classes = useStyles();
  return <>
    <Grid item xs={12} md={12} lg={8} xl={6} className={classes.mainMargin}>
      <Router>
        <div className="paddingTop paddingBottom">
          <Switch>
            <Route path="/onas">
              <About language={language} />
            </Route>
            <Route path="/kursy">
              <Courses language={language} />
            </Route>
            <Route path="/kontakt">
              <Contact language={language} />
            </Route>
            <Route path="/cookies">
              <CookiesDetails language={language} />
            </Route>
            <Route path="/faq">
              <Faq language={language} />
            </Route>
            <Route path="/Cerberek">
              <Cerberek/>
            </Route>
            <Route path="/en">
              {() => {
                setLanguage('english');
                return <Home language="english" />;
              }}
            </Route>
            <Route path="/">
              <Home language={language} />
            </Route>
          </Switch>
        </div>
      </Router>
    </Grid>
    <Grid
      item
      container
      justify="center"
      xs={12}
      style={{
        position: 'absolute', bottom: 0, left: 0, right: 0, display: 'flex',
      }}
    >
      <Footer />
    </Grid>
  </>;
}

export default Main;
