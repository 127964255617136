import React from 'react';
import CustomLink from '../../CustomLink'
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import content from "./content"

const useStyles = makeStyles(theme => ({
    priceInfo: {
        color: theme.palette.primary.main
    }
}));

function Demo(props) {
    const classes = useStyles();
    return (
        <Grid container className="marginBottom">
            <Grid item className="leftColumn" xs={12} sm={5}>
                <Grid item xs={12} sm={9}>
                    {props.img}
                </Grid>
                <Grid item xs={12} sm={9} style={{ textAlign: 'center', padding: "70px 10px" }}>
                    <Typography variant="h5">
                        {content.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item className="rightColumn" xs>
                <div className="onePixWrapper">
                    <Grid
                        item
                        className="onePixChild"
                        style={{ padding: 40 }}
                    >
                        <Typography variant='body2'>
                            {content.description}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        className={"onePixChild " + classes.priceInfo}
                        style={{
                            padding: 40,
                        }}
                    >
                        <Typography variant='body2'>
                            {content.priceInfo}
                        </Typography>
                    </Grid>
                </div>
                <Grid item xs={12} style={{ textAlign: "right", marginTop: 50 }}>
                    <CustomLink href="/kursy" upward>więcej kursów</CustomLink>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default Demo
