import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import DingDong from './Components/DingDong'
import topimage from '../../_bin/img/narzedzia.jpg'
import sala from '../../_bin/img/stol_i_palniki.jpg'
import mala from '../../_bin/img/mala_salka.jpg'
import kuchnia from '../../_bin/img/kuchnia.jpg'
import content from "./content";
import CustomLink from "../CustomLink";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  borderRight: {
    [theme.breakpoints.up('sm')]: {
      borderRight: "1px solid black"
    },
  },
  mainMargin: {
    [theme.breakpoints.up('sm')]: {
      margin: `-85px 0 50px 0`
    },
    [theme.breakpoints.down('sm')]: {
      margin: `0 0 50px 0`
    },
  }
}
));



function HomeContent() {
  const classes = useStyles();
  const leftUpLine = (
    <Hidden smUp>
      <div style={{
        position: "absolute",
        left: 10,
        borderLeft: "1px solid black",
        height: 52,
        marginTop: -10,
      }} />
    </Hidden>
  );

  const rightUpLine = (
    <Hidden smUp>
      <div style={{
        position: "absolute",
        right: 10,
        borderLeft: "1px solid black",
        height: 46,
        marginTop: -4,
      }} />
    </Hidden>
  );

  return (
    <div className={classes.mainMargin}>
      <Grid container>
        <Grid item >
          <img src={topimage} width="100%" alt="narzedzia złotnicze" />
        </Grid>
      </Grid>

      <Grid container>
      {/* className="onePixBorder" style={{ padding: "10px 20px", margin: "30px 0 0 0" }} */}
        <Grid item xs={12} sm={7} style={{minWidth: "min(800px, 100%)" }}> 
          <Typography variant='body2' style={{ padding: "50px 0" }}>
            {content.description}
             <CustomLink forward href="/kursy">
              {content.coursesLinkText}
            </CustomLink>
          </Typography>

        </Grid>
      </Grid>

      <Grid container>
        <Grid item container xs={12} sm={5} className={classes.borderRight} alignContent="flex-start">
          <Grid item xs={12} sm={8}>
            <img src={sala} alt="duża sala" width="100%" />
          </Grid>
          <Grid item xs={12}>
            {rightUpLine}
            <DingDong left title={content.bigRoom} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <img src={mala} alt="mała sala" width="100%" />
          </Grid>
          <Grid item xs={12}>
            {rightUpLine}
            <DingDong left title={content.smallRoom} />
          </Grid>
          <Hidden smDown>
            <div style={{ paddingBottom: 70 }}><br /></div>
          </Hidden>
        </Grid>

        <Grid item container xs={12} sm={7} direction="column">
          <Grid item container>
            <Grid item xs={false} sm={3}></Grid>
            <Grid item xs={12} sm={9}>
              <Typography variant='body2'>
                {content.babble}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item xs={false} sm={3}></Grid>
            <Grid item xs={12} sm={9}>
              <img src={kuchnia} width="100%" alt="kuchnia" />
            </Grid>
            <Grid item xs={12}>
              {leftUpLine}
              <DingDong right title={content.kitchen} />
            </Grid>
          </Grid>
          <Grid item container style={{ flex: "auto" }} direction="column-reverse">
            <Grid item container direction="row-reverse">
              <Grid item xs={12} sm={9} style={{ paddingBottom: 50 }}>
                <Typography variant='body2'>
                  {content.moreBabble}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
}

function Home(props) {
  return <>
    <Hidden xsDown>
      <HomeContent isMobile={false} />
    </Hidden>
    <Hidden smUp>
      <HomeContent isMobile={true} />
    </Hidden>
  </>
}
export default Home;