import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

//TODO: https://stackoverflow.com/questions/48493960/using-google-map-in-react-component obczaić stylowanie
//https://developers.google.com/maps/documentation/javascript/styling#styled_map_wizard

const goldPriceApiUrl = `http://api.nbp.pl/api/cenyzlota`

// sending request


const algo = (g) => 1.5*g

function Contact() {
  const [price, setPrice] = useState("0");
  const [date, setDate] = useState();
  useEffect(() => fetch(goldPriceApiUrl)
    .then((res) => res.json())
    .then((res) => { setPrice(res[0].cena); setDate(res[0].data); }
    ), []
  );
  return (
    <>
      <div> Kurs złota NBP: {price} PLN (na dzień: {date})</div>
      <div> Sugerowana cena sprzedaży detalicznej: </div>
      <Typography>999: {Math.round(algo(price))} PLN</Typography>
      <Typography>585: {Math.round(algo(price)*0.585)} PLN</Typography>
      <Typography>333: {Math.round(algo(price)*0.333)} PLN</Typography>
    </>
  );
};

export default Contact;
