import React from 'react';
import { Link } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

function CustomLink(props) {
    const arrow = props.forward
        ? <ArrowForwardIcon fontSize='inherit'/>
        : props.backward
            ? <ArrowBackIcon fontSize='inherit'/>
            : props.upward 
                ? <ArrowUpwardIcon fontSize='inherit'/>
                : ""

    const underline = props.noUnderline ? "none" : "always";
    return <>
        <Link
            underline={underline}
            color="textPrimary"
            href={props.href}
        >
            {props.children}
        </Link>
        <Link
            underline="none"
            color="textPrimary"
            href={props.href}
        >
            {arrow}
        </Link>
    </>
};

export default CustomLink;