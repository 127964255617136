import content from './content'
import React from 'react'
import { Typography } from '@material-ui/core'

const Faq = () => <div className="maxwidth">
    {content.map((el, index) => <div key={`FAQ_${index}`}>
        <Typography variant="body1">{index + 1}. {el.q}</Typography>
        <Typography variant="body2">{el.a}</Typography>
        <br />
    </div>)}
</div>


export default Faq