import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import content_pl from "./content_pl"
import content_en from "./content_en"
//TODO: https://stackoverflow.com/questions/48493960/using-google-map-in-react-component obczaić stylowanie
//https://developers.google.com/maps/documentation/javascript/styling#styled_map_wizard

const mapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCxIJT0mlKQVPMofia8ToOWtmJKgrJM9KI&q=Szkola+Zlotnictwa+Xerion,Krakow+Polska`
const iframe = `<iframe frameborder="0" style="border:0" src="${mapUrl}" width="100%" height="100%"></iframe>`;


function Contact(props) {
  const content = props.language === "english"
    ? content_en
    : content_pl
  return (
    <Grid container>
      <Grid item className="leftColumn" xs={12} sm={6} md={5}>
        <div style={{ height: '100%', minHeight: 400, paddingRight: 16 }} dangerouslySetInnerHTML={{ __html: iframe }} />
      </Grid>
      <Grid item className="rightColumn" xs={12} sm={6} md={7}>
        <div className="onePixWrapper">
        <Grid
            item
            className="onePixChild"
            style={{ padding: 40 }}
          >
            <Typography variant='h5'>
              {content.description.label}
            </Typography>
            <Typography variant='body2'>
              {content.description.value}
            </Typography>
          </Grid>
          <Grid
            item
            className="onePixChild"
            style={{ padding: 40 }}
          >
            <Typography variant='h5'>
              {content.email.label}
            </Typography>
            <Typography variant='body2'>
              {content.email.value}
            </Typography>
            <Typography variant='h5'>
              <br />{content.phone.label}
            </Typography>
            <Typography variant='body2'>
              {content.phone.value}
            </Typography>
          </Grid>
          <Grid
            item
            className="onePixChild"
            style={{ padding: 40 }}
          >
            <Typography variant='h5'>
              {content.address.label}
            </Typography>
            <Typography variant='body2'>
              {content.address.value.map(x => <span>{x} <br/></span>)}
            </Typography>
          </Grid>

          <Grid
            item
            className="onePixChild"
            style={{ padding: 40 }}
          >
            <Typography variant='h5'>
              {content.nip.label}
            </Typography>
            <Typography variant='body2'>
              {content.nip.value}
            </Typography>

            <Typography variant='h5'>
              <br />{content.bankAccount.label}
            </Typography>
            <Typography variant='body2'>
              {content.bankAccount.value}
            </Typography>
          </Grid>

        </div>
      </Grid>
    </Grid>
  );
}

export default Contact;
