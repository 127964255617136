import React from 'react';
import config from '../../../_config/config'

const content = {
  name: "warsztaty obrączkowe",
  description: <>
    Warsztaty odbywają się w formie jednego spotkania.
    Przyszła Młoda Para wykonuje obrączki ślubne samodzielnie
    pod opieką instruktora.<br/><br/>

    Projekt obrączek omawiamy wcześniej mailowo. <br/><br/>

    Gotowe obrączki zostaną zbadane oraz ocechowane w Urzędzie Probierczym. <br/><br/><br/>

    *istnieje możliwość rozliczenia się Państwa materiałem
  </>,
  priceInfo: <>
    czas: ok. {config.hours.weddingBandsCourseDuration}h (termin dostosowany indywidualnie) <br/>
    cena: {config.prices.weddingBands} zł + koszt materiału (w zależności od skomplikowania projektu, każda kolejna godzina {config.prices.extraCourseHour} zł) <br/>
  </>,

  extraInfoLabel: <>ważne informacje</>,
  extraInfo: <>
    Przed rozpoczęciem zajęć należy sprecyzować wygląd, szerokość oraz rozmiar obrączek<br/><br/>

    Pracujemy jedynie w próbie 0,585 (14k) złota.<br/><br/>

    Pobieramy dodatkową opłatę za przygotowanie białego (+30%) i różowego (+15%) złota.<br/><br/>

    Jeśli macie własne złoto, możemy się nim rozliczyć. W takiej sytuacji wymieniamy 1g złota próby 0,585 na 1g złota waszego stopu próby 0,585 (jeśli wasze złoto jest innej próby, nie martwcie się, przeliczymy :)) Nie wykorzystujemy tzw. złota sentymentalnego, więc nie ma możliwości wykonania Waszych obrączek dokładnie z tego samego złota, które przyniesiecie.<br/>
  </>,
  bandTypesLabel: <>typ obrączki</>,
  bandTypesArray: [
    <>01 barwa złota | żółte, białe, różowe</>,
    <>02 profil | płaski, płaski ze ściętymi brzegami, półokrągły, soczewkowy</>,
    <>03 szerokość | dowolna</>,
    <>04 wykończenie | mat, błysk, młotkowanie</>,
  ]
}

export default content;