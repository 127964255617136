import React from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Main from './components/Main';
import Header from './components/Header';
import CookieInfo from './components/CookieInfo';
import './css/common.css';

const commonFont = {
  fontFamily: 'Public Sans',
};

const commonFontWithSpacing = {
  ...commonFont,
  letterSpacing: 1.3,
  wordSpacing: 3,
};

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 2000,
    },
  },
  palette: {
    primary: {
      main: '#AA8844',
    },
    secondary: {
      main: '#C3B375',
      light: '#C3B375',
    },
  },
  typography: {
    caption: {
      fontSize: 'calc(10px + 0.25vw)',
      fontWeight: 600,
      ...commonFont,
    },
    body1: {

    },
    body2: {

    },
    h6: {
      ...commonFontWithSpacing,
      fontSize: 'calc(18px + 0.15vw)',
    },
    h5: {
      ...commonFontWithSpacing,
      fontWeight: 700,
      fontSize: 24, // 'calc(20px + 0.25vw)'
    },
    link: {
      color: 'black',
      textDecoration: 'underline',
    },
  },
});

theme.typography.body1 = {
  ...commonFontWithSpacing,
  lineHeight: 1.7,
  fontWeight: 900,
  fontSize: 18, // 'calc(12px + 0.25vw)'
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
};

theme.typography.body2 = {
  ...commonFont,
  letterSpacing: 1,
  wordSpacing: 1.5,
  lineHeight: 1.7,
  fontSize: 18,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
};

theme.typography.cookieInfo = {
  ...commonFont,
  letterSpacing: 1,
  wordSpacing: 1.5,
  lineHeight: 1.7,
  fontSize: 16,
};

// theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <link rel="icon" href="favi.svg" />
      <div style={{ position: 'relative' }}>
        <Grid container justify="center" style={{ position: 'fixed', backgroundColor: 'white', zIndex: 10 }}>
          <Header />
        </Grid>
        <Grid container justify="center" style={{ minHeight: '100vh' }}>
          <Main />
        </Grid>
      </div>
      <CookieInfo />
    </ThemeProvider>
  );
}

export default App;
