import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, Typography, Button, Hidden } from '@material-ui/core';
import content from "./content"
import CustomLink from '../CustomLink'

// window.localStorage.removeItem('cookies') // TODO REMOVE
const useStyles = makeStyles(theme => ({
    padder: {
        padding: '10px 20px',
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: "1px"
    },
    bolder: {
        fontWeight: 700,
        color: "black"
    },
    pointer: {
        cursor: "pointer"
    }
}));

const CookieInfo = (props) => {
    const [cookiesVisibility, setCookiesVisibility] = useState(window.localStorage.getItem('cookies') || 'visible');
    const saveCookiesHidden = () => {
        setCookiesVisibility('hidden');
        window.localStorage.setItem("cookies", "hidden")
    }
    console.log(cookiesVisibility);
    const classes = useStyles();
    return <Snackbar
        open={cookiesVisibility !== "hidden"}
        // anchorOrigin={{vertical: 'bottom', horizontal: 'left' }}
        placement="bottom"
    >
        <div className={classes.padder}>
            <Hidden mdDown>
                <Typography variant='cookieInfo'>
                    <span className={classes.bolder}> {content.info} </span>|
                    <span> <CustomLink href="/cookies" noUnderline>{content.more}</CustomLink> </span>|
                    <span className={classes.bolder+" "+classes.pointer} onClick={saveCookiesHidden}> {content.agree}</span>
                </Typography>
            </Hidden>
            <Hidden lgUp>
                <Typography variant='cookieInfo' style={{textAlign: "center"}}>
                <span className={classes.bolder}> {content.info} </span><br/>
                <span> <CustomLink href="/cookies" nUnderline>{content.more}</CustomLink> </span><br/>
                {/* //variant="outlined" */}
                <span className={classes.bolder}><Button  onClick={saveCookiesHidden}>{content.agree}</Button></span> 
                </Typography>
            </Hidden>            
        </div>
    </Snackbar>
}

export default CookieInfo
