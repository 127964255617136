import React from 'react';
import config from '../../../_config/config'

const content = {
  name: "kurs regularny",
  description: <>
    Całość kursu regularnego jest podzielona na sześć semestrów.
    W{'\u00A0'}systemie regularnym spotykamy się raz w{'\u00A0'}tygodniu po {config.hours.regularCourseMeeingDuration}h.
    Zajęcia odbywają się w{'\u00A0'}kameralnych grupach – do pięciu osób.<br /><br />

    termin: październik – styczeń / marzec – czerwiec (godziny zajęć są orientacyjne, dni ustalane indywidualnie):<br />
    pon-pt: grupa poranna ({config.hours.weekdays.morning}) grupa wieczorna ({config.hours.weekdays.evening})<br />
    sb-nd: grupa poranna ({config.hours.weekend.morning})<br /><br /><br />

    *jeśli nie jesteś początkujący - poinformuj nas o tym, abyśmy mogli przydzielić cię do odpowiedniej grupy<br />
    *możliwe niewielkie modyfikacje godzin zajęć<br />
    *5% zniżki za kontynuację nauki w następujących po sobie semestrach<br />
    *dodatkowa godzina lekcji kosztuje {config.prices.extraCourseHour} zł<br />
  </>,
  priceInfo:<>
    spotkania: 1x w tygodniu<br />
    czas trwania semestru: {config.hours.regularCourseTotalDuration}h ({config.hours.regularCourseMeeingDuration}h x {config.hours.regularCourseTotalDuration/config.hours.regularCourseMeeingDuration} spotkań)<br />
    cena za semestr: {config.prices.semester} zł (możliwość rozłożenia na 3 raty)</>,
  curriculumLabel: <>zakres kursu</>,
  curriculumDescription: <>
    zasady BHP, chemia w złotnictwie, wycinanie, wiercenie, gięcie, lutowanie, nitowanie, szlifowanie, szmerglowanie, topienie, odlewanie, walcowanie, przeciąganie, obsługa profesjonalnych przyrządów złotniczych, probiernictwo, badanie stopów, gemmologia, rodzaje szlifów, rodzaje opraw kamieni, naprawa biżuterii, praca ze złotem
  </>,
  semesterLabel: <>semestr</>,
  projectTypeLabel: <>rodzaj projektu</>,
  projectTypePerSemester: [
    <>zawieszka, obrączka płaska, obrączka półokrągła, pierścionek bez kamienia</>,
    <>pierścionek z kamieniem, kolczyki, wykańczanie odlewu</>,
    <>broszka, sygnet, spinki do mankietów</>,
    <>zapięcia, bransoleta</>,
    <>praca ze złotem (indywidualnie wybrany projekt)</>,
    <>praca końcowa (opracowanie i realizacja projektu)</>
  ]
}

export default content;