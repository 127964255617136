import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import content from "./content"
import CustomLink from '../../CustomLink'

const useStyles = makeStyles(theme => ({
    priceInfo: {
        color: theme.palette.primary.main
    }
}));

function Regular(props) {
    const classes = useStyles();
    const numberOfSemesters = content.projectTypePerSemester.length
    const calculatePadding = (index) => (index === 0)
        ? "40px 40px 0px 40px"
        : (index === numberOfSemesters - 1)
            ? "10px 40px 40px 40px"
            : "10px 40px"

    return (
        <>
            <Grid container className="marginBottom">
                <Grid item className="leftColumn" xs={12} sm={5}>
                    <Grid item xs={12} sm={9}>
                        {props.img}
                    </Grid>
                    <Grid item xs={12} sm={9} style={{ textAlign: 'center', padding: "70px 10px" }}>
                        <Typography variant="h5">
                            {content.name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item className="rightColumn" xs>
                    <div className="onePixWrapper">
                        <Grid
                            item
                            className="onePixChild"
                            style={{ padding: 40 }}
                        >
                            <Typography variant='body2'>
                                {content.description}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            className={"onePixChild " + classes.priceInfo}
                            style={{
                                padding: 40,
                            }}
                        >
                            <Typography variant='body2'>
                                {content.priceInfo}
                            </Typography>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            <Grid container className="marginBottom onePixWrapper">
                <Grid item className="leftColumn" xs={12} sm={5}>
                    <Grid
                        item
                        className="onePixChild"
                        style={{
                            padding: 40,
                            height: "100%"
                        }}>
                        <Typography variant='body2'>
                            {content.curriculumLabel}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item className="rightColumn" xs>

                    <Grid
                        item
                        className="onePixChild"
                        style={{
                            padding: 40,
                        }}
                    >
                        <Typography variant='body2'>
                            {content.curriculumDescription}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="marginBottom onePixWrapper">
                <Hidden xsDown>
                    <Grid item className="leftColumn" xs={2} sm={5}>
                        <Grid
                            item
                            className="onePixChild"
                            style={{
                                padding: 40,
                                height: "100%"
                            }}>
                            <Typography variant='body2'>
                                {content.semesterLabel}
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>

                <Grid item className="rightColumn" xs={12} sm={7}>

                    <Grid
                        item
                        className="onePixChild"
                        style={{
                            padding: 40,
                        }}
                    >
                        <Typography variant='body2'>
                            {content.projectTypeLabel}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className="onePixChild">
                    {content.projectTypePerSemester.map((v, index) => {
                        return <Hidden xsDown>
                            <Grid
                                item
                                className="leftColumn, onePixRight"
                                xs={2}
                                sm={5}
                                style={{
                                    textAlign: "right",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    padding: calculatePadding(index),
                                    paddingRight: 0
                                }}
                            >
                                <Typography variant='body2'>
                                    0{index + 1}
                                    <hr
                                        style={{
                                            display: 'inline-block'
                                        }}
                                        width={`${index + 2}0%`}
                                    />
                                </Typography><br />

                            </Grid>

                            <Grid
                                item
                                className="rightColumn"
                                xs={12}
                                sm={7}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    padding: calculatePadding(index)
                                }}
                            >


                                <Typography variant='body2'>{v}</Typography><br />
                            </Grid>

                        </Hidden>
                    })}
                </Grid>
                <Hidden smUp>
                    <Grid item className="rightColumn" xs={12} sm={7}>
                        <Grid
                            item
                        >
                            {content.projectTypePerSemester.map((v, index) => {
                                return <div key={index}>
                                    <Typography variant='body2'>semestr 0{index + 1} {v}</Typography><br />
                                </div>
                            })}
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right", marginTop: 10 }}>
                <CustomLink href="/kursy" upward>więcej kursów</CustomLink>
            </Grid>
        </>
    )
}

export default Regular
